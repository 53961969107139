import "./App.css";
import "./resources/components/NavBar/NavBar";
import Router from "./resources/components/Router/Router";
import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
// import { addProducts } from "./services/db.js";
import Notification from "./resources/components/Notification/Notification";
import { getVacations } from "./services/db";

const date = new Date();

function App() {
  const [, setCookie] = useCookies(["Telpower.com.ar"]);
  const [vacations, setVacations] = useState(null); // Inicializado como null para manejar estados indefinidos

  useEffect(() => {
    // Generar la cookie al cargar la página
    setCookie("Telpower.com.ar", "value", {
      sameSite: "none",
      secure: true,
    });
  }, [setCookie]);

  useEffect(() => {
    // Obtener vacaciones solo una vez al montar el componente
    getVacations().then((resp) => {
      setVacations(resp);
    });
  }, []); // Dependencia vacía para evitar bucles infinitos

  return (
    <div>
      {vacations &&
        (date.getMonth() + 1 >= 11 || date.getMonth() + 1 === 1) && (
          <Notification
            title="Vacaciones!"
            description={`No estaremos atendiendo desde el ${vacations.vacationsFrom.formattedDate} hasta el ${vacations.vacationsTo.formattedDate}`}
          />
        )}
      <Router className="backgr" />
    </div>
  );
}

export default App;
