export function TimestampToDate(firebaseTimestamp) {
  // Verificar si el timestamp existe
  if (!firebaseTimestamp?.seconds) {
    return "error";
  }

  // Timestamp de Firebase a un objeto Date
  const date = new Date(firebaseTimestamp.seconds * 1000);

  // Extraer partes de la fecha
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Los meses empiezan desde 0
  const year = date.getFullYear();

  // Retornar un objeto con las partes de la fecha
  return {
    day,
    month,
    year,
    formattedDate: `${day}/${month}/${year}`, // También puedes incluir el formato completo
  };
}
